import React from 'react'

// Components
import Footer from '../components/Footer'
import SEO from '../components/seo'

// Style
import style from './about.module.css'

const About = ({
	location
}: {
	location: {
		href: string
	}
}) => (
	<section className={ style.About }>
		<SEO title='About' url={ location.href } />
		<h1 className={ style.h1 }>A<br />Long-haired<br />Handsome<br />Jesus</h1>
		<h2>About</h2>
		<p><em>A Long-Haired Handsome Jesus</em> is a series of autobiographical vignettes about growing up in small town Texas with a colorful cast of characters, most of whom are family. Originally conceived in the early 90s during my first years as a high school English teacher, the project began as a storytelling playlist, late-night fodder for entertaining friends. From many a porch and pub, I had shared bits of these stories and heard the refrain: <em>You’ve got to write these down.</em> But I didn’t. Ultimately I found some of these stories (at least the more appropriate ones) sneaking into my classroom, particularly when teaching creative writing. Then it was my students saying, <em>You’ve got write these down, Mr. B.</em> Still, I hesitated. Coaching writing, evaluating and editing writing is–for me–a much easier task than the act of writing itself, especially when the topic is <em>myself.</em> Maybe I needed time and distance. Maybe I just wasn’t ready.</p>
		<p>Until now.</p>
		<p>With my partner at the helm of all things web design and development, we will be publishing a chapter installment for <em>A Long-Haired Handsome Jesus</em> twice a month. We’re excited to see where this journey will take us. And we invite you to come along for the ride.</p>
		<h2>Michael Blankenburg, Author</h2>
		<p>Michael Blankenburg grew up in the East Texas oil town of Kilgore, once known for having “The World’s Richest Acre” and home to the world-famous precision dance team, The Kilgore College Rangerettes. Neither a beneficiary of the historic oil boom nor a talented high-stepper, Michael headed for—or rather ran to—Austin at the age of eighteen to attend The University of Texas where he majored in Education.</p>
		<p>Upon graduation, Michael taught high school English and Creative Writing in Austin, Texas, for eighteen years with two-year stints in Houston and New York City, where he earned a Master of Arts in English Education at Columbia Teachers College.</p>
		<p>In May of 2012, Michael returned to New York City with his partner-now-spouse, Brandon Durham, where they live in close quarters with their chocolate lab and three aging cats happily underfoot.</p>
		<p>You can reach Michael at <a href="mailto:burg66@gmail.com">burg66@gmail.com</a></p>
		<h2>Corbin Lewars, Editor</h2>
		<p>Corbin Lewars, m.ed. is a writing coach and developmental editor with over twelve years of experience. She works with clients in every step of the writing process: setting deadlines and goals, strengthening their craft and offering feedback on their writing, walking them through the publishing process and helping them find an agent or publisher. Her clients include University of Washington School of Law, Lesson Ladder Publishing, a northwest publisher, various educational and spiritual centers, and emerging and published authors.</p>
		<p>Her memoir <a href="http://corbinlewars.com/creating_a_life">Creating a Life</a> was nominated for the Washington State and Pacific Northwest Booksellers awards and her essays have been featured in over twenty-five publications and anthologies. She currently writes for two papers in Seattle, where she lives with her two children. To learn more about Corbin and her services, view <a href="http://www.corbinlewars.com/">www.corbinlewars.com</a>.</p>
		<p>You can reach Corbin at <a href="mailto:corbin@corbinlewars.com">corbin@corbinlewars.com</a></p>
		<h2>Brandon Durham, Designer/Developer</h2>
		<p>Torn between being a reenactment actor on Unsolved Mysteries; an audio engineer; a professional victim in horror films; a landscape architect; a sad rock musician; or an Artist™ (whatever that means), Brandon chose the most difficult yet rewarding of paths: Being the guy that can’t pick just one title. He’s done time at a bakery not ever baking anything; making records with his sad rock band <a href="https://palaxytracks.com">Palaxy Tracks</a>; as a [former] member of The Octopus Project; making lots of “Click Here!!!” buttons at an ad agency; at a marketing agency proving that “Click Here!!!” buttons don’t actually work; and running a one-man development/design studio called <a href="https://smallparade.com">Small Parade</a>.</p>
		<p>You can reach Brandon at <a href="mailto:brandon@smallparade.com">brandon@smallparade.com</a>.</p>
		<Footer />
	</section>
)

export default About
